/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";

.pointer{
	cursor: pointer;
}

body[data-theme-color=app-theme-megaflor] {
    --highlight: #235a9a;
    --highlight-100: rgba(59,89,152,0.2);
    --highlight-200: rgba(59,89,152,0.4);
    --highlight-300: rgba(59,89,152,0.6);
    --highlight-400: rgba(59,89,152,0.8);
    --highlight-500: #235a9a;
    --highlight-600: #3a5795;
    --highlight-700: #395592;
    --highlight-800: #37548f;
    --highlight-900: #36528c;
    --nav-bar-highlight-background: #235a9a;
}
body[data-theme-color=app-theme-megaflor2] {
  --highlight: #235a9a;
  --highlight-100: rgba(59,89,152,0.2);
  --highlight-200: rgba(59,89,152,0.4);
  --highlight-300: rgba(59,89,152,0.6);
  --highlight-400: rgba(59,89,152,0.8);
  --highlight-500: #235a9a;
  --highlight-600: #3a5795;
  --highlight-700: #395592;
  --highlight-800: #37548f;
  --highlight-900: #36528c;
  --nav-bar-highlight-background: #235a9a;
}

.input-src.spinners {
  background-image: url("./assets/images/loading.svg");
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: right;
}
/*
.element {
  height: 100px;
  width: 200px;
  background: #ccc;
  text-align: center;
  color: #2d2d2d;
  padding: 10px;
  position: absolute;
}

.element span {
  font-size: 14px;
  line-height: 100px;
}
*/

app-context-menu {
  width: 170px;
  text-align: left;
  box-shadow: 2px 2px 10px #656;
  border-radius: 5px;
  background: #235a9a;
  color: #ffffff;
  padding: 2px;
  font-size: 12px;
}

app-context-menu-two {
  width: 170px;
  text-align: left;
  box-shadow: 2px 2px 10px #656;
  border-radius: 5px;
  background: #235a9a;
  color: #ffffff;
  padding: 2px;
  font-size: 12px;  
}

.swal2-input {
    width: 100% !important;
    padding: 0.075rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    appearance: none !important;
    border-radius: .25rem !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out !important;
}

.swal2-content {
   text-align: left !important;
   font-size: 1rem !important;
   font-weight: 400 !important;
   line-height: 20px !important;
}

.swal2-input:focus, .swal2-textarea:focus {
    box-shadow: 0 0 0 1px rgb(100 150 200 / 50%) !important;
}

.swal2-input{
    margin: 0.5em auto !important;
}

.swal2-label{
  display: inline-block !important;
  text-align: left !important;
}

.swal2-header {
    padding-bottom: 12px !important;
}

.swal2-popup-oters {
  width: 40em !important;
}

:host {
  display: block;
  background: white;
}

h1 {
  margin: 0;
  padding: 1em;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1000;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
    background: #000;
    opacity: 0.55 !important;
}

.cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
}

.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    background: white;
} 

.tm-file-preview-dialog-panel{
  width: 900px;
  height: 600px;
}

.mat-dialog-container {
    display: block;
    padding: 24px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
}

.mat-dialog-container{
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    background: #fff;
    color: rgba(0,0,0,.87);
}